import { Link } from "gatsby";
import React, { useEffect } from "react";
import { Container } from "../styles/UI";
import { navigate } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import styled from "styled-components";

import taxcelBgLogo from "../images/home/logo-negative.svg";
import Button from "../DesignSystem/Button";

const Hero = styled.section`
  width: 100%;
  background: ${(p) => p.theme.degradeAzul};
  margin-top: -180px;
  padding-top: 180px;
  padding-bottom: 0px;
  color: var(--color-white);
  box-sizing: border-box;
  overflow: hidden;

  .buttons {
    button {
      margin-top: 16px;
    }
    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      display: flex;
      align-items: center;

      button:first-child {
        margin-right: 24px;
      }
    }
  }

  button:last-child:hover {
    background: var(--color-white);
  }

  button:last-child {
    box-shadow: 0px 8px 16px -4px #05185b;
  }

  ${Container} {
    .cta {
      position: relative;
      z-index: 10;

      h1 {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: -0.01em;
        margin-bottom: 24px;
        line-height: 1.25;
      }

      p {
        font-weight: 350;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 24px;
      }
    }

    .illustration {
      z-index: 1;
      margin-top: 0px;
      position: relative;

      .bg-logo {
        position: absolute;
        z-index: 0;
        height: 800px;
        right: -180px;
        top: -240px;

        @media (min-width: ${(p) => p.theme.bp.tablet}) {
          left: -100px;
          top: -580px;
          height: 1360px;
        }
      }
    }

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      max-height: 820px;
      display: grid;
      /* grid-template-columns: repeat(2, 1fr); */
      grid-template-columns: minmax(600px, 1fr) 1fr;
      grid-column-gap: 32px;
      align-items: center;

      h1 {
        font-size: 35px;
        margin-top: 64px;
      }
    }
  }
`;

const Tabelas = styled.div`
  padding: 80px 40px;

  h1 {
    margin-bottom: 36px;
    font-weight: 600;
    font-size: 36px;
    line-height: 56px;
    /* or 123% */

    text-align: center;
    letter-spacing: -1px;
    background: ${(p) => p.theme.degradeMisto2};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    line-height: 42px;

    span {
      font-size: 50px;
    }
  }

  h2 {
    font-weight: 450;
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 24px;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  line-height: 1.4;
  font-size: 14px;
  background: white;

  td {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    padding: 10px 5px;
    word-break: normal;
    vertical-align: middle;
    text-align: left;
  }
  th {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    padding: 10px 5px;
    word-break: normal;
    background-color: #00467f;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    color: white;
  }
`;

export default function PoliticaPrivacidade() {
  const handleClick = () => {
    navigate("/");
  };
  const handleClickBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    document.querySelectorAll("[rowspan]").forEach((element) => {
      let rowspanValue = parseInt(element.getAttribute("rowspan"), 10);
      if (!isNaN(rowspanValue) && rowspanValue > 1) {
        element.setAttribute("title", element.textContent.trim());
      }
    });
  }, []);

  return (
    <Layout>
      <Seo title="Guia Completo de Códigos do IBS e CBS" />
      <Hero>
        <Container>
          <div className="cta"></div>
          <div className="illustration"></div>
        </Container>
      </Hero>
      <Tabelas>
        <Container>
          <h1>Guia Completo de Códigos do IBS e CBS - Reforma Tributária</h1>
          <h2>
            Veja abaixo tabela dos códigos CClass e CST com a respectiva
            descrição e apontamento do artigo da Lei Complementar 214/2025.
          </h2>
          <Table className="tg">
            <thead>
              <tr>
                <th>CST-IBS/CBS</th>
                <th>Descrição CST-IBS/CBS</th>
                <th>cClassTrib</th>
                <th>Descrição cClassTrib</th>
                <th>LC</th>
                <th>LC Redação</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="8">000</td>
                <td rowspan="8">Tributação integral</td>
                <td>000001</td>
                <td>Situações tributadas integralmente pelo IBS e CBS.</td>
                <td> </td>
                <td> </td>
              </tr>
              <tr>
                <td>000002</td>
                <td>Exploração de via.</td>
                <td>Art. 11, VIII</td>
                <td>
                  Art. 11.VIII - serviço de exploração de via, mediante
                  cobrança de valor a qualquer título, incluindo tarifas,
                  pedágios e quaisquer outras formas de cobrança, o território
                  de cada Município e Estado, ou do Distrito Federal,
                  proporcionalmente à correspondente extensão da via explorada;
                </td>
              </tr>
              <tr>
                <td>000003</td>
                <td>Regime automotivo - projetos incentivados.</td>
                <td>Art. 310</td>
                <td>
                  Art. 310. Em relação aos projetos habilitados à fruição dos
                  benefícios estabelecidos pelo art. 11-C da Lei no 9.440, de 14
                  de março de 1997, o crédito presumido de que trata o art. 308
                  será calculado mediante a aplicação dos seguintes percentuais
                  sobre o valor das vendas no mercado interno, em cada mês, dos
                  produtos constantes nos projetos de que trata o art. 308,
                  fabricados ou montados nos estabelecimentos incentivados: 
                </td>
              </tr>
              <tr>
                <td>000004</td>
                <td>Bares e Restaurantes</td>
                <td>Art. 274</td>
                <td>
                  Art. 274. As alíquotas do IBS e da CBS corresponderão a
                  percentual das alíquotas-padrão de cada ente federativo, o
                  qual será fixado de modo a resultar, quando aplicado sobre as
                  alíquotas de referência, em carga tributária equivalente
                  àquela incidente sobre os bares e restaurantes, inclusive
                  lanchonetes, em decorrência das operações de fornecimento de
                  alimentação e das bebidas de que trata o § 1º do art. 272
                  desta Lei Complementar.
                </td>
              </tr>
              <tr>
                <td>000005</td>
                <td>Hotelaria, Parques de Diversão e Parques Temáticos</td>
                <td>Art. 280</td>
                <td>
                  Art. 280. As alíquotas do IBS e da CBS corresponderão a
                  percentual das alíquotas-padrão de cada ente federativo, o
                  qual será fixado de modo a resultar, quando aplicado sobre as
                  alíquotas de referência, em carga tributária equivalente
                  àquela incidente sobre os serviços de hotelaria, parques de
                  diversão e parques temáticos.
                </td>
              </tr>
              <tr>
                <td>000006</td>
                <td>
                  Transporte coletivo de passageiros rodoviário, ferroviário e
                  hidroviário intermunicipais e interestaduais
                </td>
                <td>Art. 285, I</td>
                <td>
                  Art. 285. Em relação aos serviços de transporte coletivo de
                  passageiros rodoviário, ferroviário e hidroviário
                  intermunicipais e interestaduais:
                  <br /> I - as alíquotas do IBS e da CBS corresponderão a
                  percentual das alíquotas de cada ente, o qual será fixado de
                  modo a resultar, quando aplicado sobre as alíquotas de
                  referência, em carga tributária equivalente àquela incidente
                  nos serviços de transporte coletivo de passageiros rodoviário,
                  ferroviário e hidroviário intermunicipais e interestaduais,
                  aplicando-se o disposto nos §§ 1º a 5º do art. 280 desta Lei
                  Complementar quanto ao cálculo a ser efetuado;
                </td>
              </tr>
              <tr>
                <td>000007</td>
                <td>Agências de Turismo</td>
                <td>Art. 289, II</td>
                <td>
                  Art. 289. Nos demais serviços de intermediação prestados por
                  agências de turismo:
                  <br />  II - a alíquota é a mesma aplicável aos serviços de
                  hotelaria, parques de diversão e parques temáticos;
                </td>
              </tr>
              <tr>
                <td>000008</td>
                <td>
                  Aquisição de bens materiais de construção aplicados em obras
                  de construção civil.
                </td>
                <td>Art. 262</td>
                <td>
                  Art. 262. São vedadas a apropriação e a utilização construção
                  civil nas aquisições de materiais de construção aplicados nas
                  obras contratadas. 
                </td>
              </tr>
              <tr>
                <td rowspan="46">200</td>
                <td rowspan="23">Alíquota zero</td>
                <td>200001</td>
                <td>
                  Venda de dispositivos médicos com a especificação das
                  respectivas classificações da NCM/SH previstas no Anexo XII da
                  Lei Complementar nº … , de 2024, observado o art. 139 da Lei
                  Complementar nº …., de 2024.
                </td>
                <td>Art. 139, I</td>
                <td>
                  de créditos de IBS e de CBS pelo fornecedor de serviços de
                </td>
              </tr>
              <tr>
                <td>200002</td>
                <td>
                  Venda de dispositivos médicos com a especificação das
                  respectivas classificações da NCM/SH previstas no Anexo IV da
                  Lei Complementar nº … , de 2024, quando adquiridos por órgãos
                  da administração pública direta, autarquias e fundações
                  públicas, observado o art. 139 da Lei Complementar nº …., de
                  2024.
                </td>
                <td>Art. 139, II</td>
                <td>
                  Art. 139. Ficam reduzidas a zero as alíquotas do IBS e da CBS
                  incidentes sobre a venda dos dispositivos médicos
                  relacionados:
                  <br /> II - no Anexo IV, com a especificação das respectivas
                  classificações da NCM/SH, quando adquiridos por órgãos da
                  administração pública direta, autarquias e fundações públicas;
                </td>
              </tr>
              <tr>
                <td>200003</td>
                <td>
                  Situação de emergência de saúde pública reconhecida pelo Poder
                  Legislativo federal, estadual, distrital ou municipal
                  competente, ato conjunto do Ministro da Fazenda e do Comitê
                  Gestor do IBS poderá ser editado, a qualquer momento, para
                  incluir dispositivos não listados no Anexo XII da Lei
                  Complementar nº …, de 2024, limitada a vigência do benefício
                  ao período e à localidade da emergência de saúde pública.
                </td>
                <td>Art. 139, § 3º</td>
                <td>
                  Art. 139. Ficam reduzidas a zero as alíquotas do IBS e da CBS
                  incidentes sobre a venda dos dispositivos médicos
                  relacionados:
                  <br /> § 3º Em caso de emergência de saúde pública reconhecida
                  pelo Poder Legislativo federal, estadual, distrital ou
                  municipal competente, ato conjunto do Ministro da Fazenda e do
                  Comitê Gestor do IBS poderá ser editado, a qualquer momento,
                  para incluir dispositivos não listados no Anexo XII desta Lei
                  Complementar, limitada a vigência do benefício ao período e à
                  localidade da emergência de saúde pública.
                </td>
              </tr>
              <tr>
                <td>200003</td>
                <td>
                  Fornecimento dos dispositivos de acessibilidade próprios para
                  pessoas com deficiência relacionados no Anexo XIII da Lei
                  Complementar nº …., de 2024, com a especificação das
                  respectivas classificações da NCM/SH, observado o art. 140 da
                  Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 140, I</td>
                <td>
                  Art. 140. Ficam reduzidas a zero as alíquotas do IBS e da CBS
                  incidentes sobre o fornecimento dos dispositivos de
                  acessibilidade próprios para pessoas com deficiência
                  relacionados:
                  <br /> I - no Anexo XIV, com a especificação das respectivas
                  classificações da NCM/SH; e
                </td>
              </tr>
              <tr>
                <td>200004</td>
                <td>
                  Fornecimento dos dispositivos de acessibilidade próprios para
                  pessoas com deficiência relacionados no Anexo V da Lei
                  Complementar nº …., de 2024, com a especificação das
                  respectivas classificações da NCM/SH, quando adquiridos por
                  órgãos da administração pública direta, autarquias e fundações
                  públicas, observado o art. 140 da Lei Complementar nº …., de
                  2024.
                </td>
                <td>Art. 140, II</td>
                <td>
                  Art. 140. Ficam reduzidas a zero as alíquotas do IBS e da CBS
                  incidentes sobre o fornecimento dos dispositivos de
                  acessibilidade próprios para pessoas com deficiência
                  relacionados:
                  <br /> II - no Anexo V, com a especificação das respectivas
                  classificações da NCM/SH, quando adquiridos por órgãos da
                  administração pública direta, autarquias e fundações públicas.
                </td>
              </tr>
              <tr>
                <td>200005</td>
                <td>
                  Fornecimento dos medicamentos relacionados no Anexo XIV da Lei
                  Complementar nº …, de 2024, com a especificação das
                  respectivas classificações da NCM/SH, observado o art. 141 da
                  Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 141, I</td>
                <td>
                  Art. 141. Ficam reduzidas a zero as alíquotas do IBS e da CBS
                  sobre o fornecimento dos medicamentos:
                  <br /> I - relacionados no Anexo XV, com a especificação das
                  respectivas classificações da NCM/SH;
                </td>
              </tr>
              <tr>
                <td>200006</td>
                <td>
                  Fornecimento dos medicamentos registrados na Anvisa, quando
                  adquiridos por órgãos da administração pública direta,
                  autarquias e fundações públicas, observado o art. 141 da Lei
                  Complementar nº …., de 2024.
                </td>
                <td>Art. 141, II</td>
                <td>
                  Art. 141. Ficam reduzidas a zero as alíquotas do IBS e da CBS
                  sobre o fornecimento dos medicamentos:
                  <br /> II - registrados na Anvisa, quando adquiridos por
                  órgãos da administração pública direta, autarquias e fundações
                  públicas.
                </td>
              </tr>
              <tr>
                <td>200007</td>
                <td>
                  Fornecimento das composições para nutrição enteral e
                  parenteral, composições especiais e fórmulas nutricionais
                  destinadas às pessoas com erros inatos do metabolismo
                  relacionadas no Anexo VI desta Lei Complementar, com a
                  especificação das respectivas classificações da NCM/SH, quando
                  adquiridas por órgãos da administração pública direta,
                  autarquias e fundações públicas.
                </td>
                <td>Art. 141, § 1º</td>
                <td>
                  Art. 141. Ficam reduzidas a zero as alíquotas do IBS e da CBS
                  sobre o fornecimento dos medicamentos:
                  <br /> § 1º A redução de alíquotas prevista no caput deste
                  artigo aplica-se também ao fornecimento das composições para
                  nutrição enteral e parenteral, composições especiais e
                  fórmulas nutricionais destinadas às pessoas com erros inatos
                  do metabolismo relacionadas no Anexo VI desta Lei
                  Complementar, com a especificação das respectivas
                  classificações da NCM/SH, quando adquiridas por órgãos da
                  administração pública direta, autarquias e fundações públicas.
                </td>
              </tr>
              <tr>
                <td>200008</td>
                <td>
                  Situação de emergência de saúde pública reconhecida pelo Poder
                  Legislativo federal, estadual, distrital ou municipal
                  competente, ato conjunto do Ministro da Fazenda e do Comitê
                  Gestor do IBS poderá ser editado, a qualquer momento, para
                  incluir dispositivos não listados no Anexo XIV da Lei
                  Complementar nº …, de 2024, limitada a vigência do benefício
                  ao período e à localidade da emergência de saúde pública.
                </td>
                <td>Art. 141, § 3º</td>
                <td>
                  Art. 141. Ficam reduzidas a zero as alíquotas do IBS e da CBS
                  sobre o fornecimento dos medicamentos:
                  <br /> § 3º Em caso de emergência de saúde pública reconhecida
                  pelo Poder Legislativo federal, estadual, distrital ou
                  municipal competente, ato conjunto do Ministro da Fazenda e do
                  Comitê Gestor do IBS poderá ser editado, a qualquer momento,
                  para incluir medicamentos não listados no Anexo XIV desta Lei
                  Complementar, limitada a vigência do benefício ao período e à
                  localidade da emergência de saúde pública.
                </td>
              </tr>
              <tr>
                <td>200009</td>
                <td>
                  Fornecimento de tampões higiênicos, absorventes higiênicos
                  internos ou externos, descartáveis ou reutilizáveis, calcinhas
                  absorventes e coletores menstruais, relacionados no código
                  9619.00.00 da NCM/SH, observado o art. 142 da Lei Complementar
                  nº …., de 2024.
                </td>
                <td>Art. 142, I, II, III</td>
                <td>
                  Art. 142. Ficam reduzidas a zero as alíquotas do IBS e da CBS
                  incidentes sobre o fornecimento dos seguintes produtos de
                  cuidados básicos à saúde menstrual:
                  <br /> I - tampões higiênicos classificados no código
                  9619.00.00 da NCM/ SH;
                  <br /> II - absorventes higiênicos internos ou externos,
                  descartáveis ou reutilizáveis, e calcinhas absorventes
                  classificados no código 9619.00.00 da NCM/SH; e<br /> III -
                  coletores menstruais classificados no código 9619.00.00 da
                  NCM/SH.
                </td>
              </tr>
              <tr>
                <td>200010</td>
                <td>
                  Fornecimento dos produtos hortícolas, frutas e ovos,
                  relacionados no Anexo XV da Lei Complementar nº …. , de 2024,
                  com a especificação das respectivas classificações da NCM/SH e
                  desde que não cozidos, observado o art. 143 da Lei
                  Complementar nº …., de 2024.
                </td>
                <td>Art. 143</td>
                <td>
                  Art. 143. Ficam reduzidas a zero as alíquotas do IBS e da CBS
                  incidentes sobre o fornecimento dos produtos hortícolas,
                  frutas e ovos relacionados no Anexo XVI, com a especificação
                  das respectivas classificações da NCM/SH
                </td>
              </tr>
              <tr>
                <td>200011</td>
                <td>
                  Prestação de serviços de pesquisa e desenvolvimento por
                  Instituição Científica, Tecnológica e de Inovação (ICT) sem
                  fins lucrativos para a administração pública direta,
                  autarquias e fundações públicas ou para o contribuinte sujeito
                  ao regime regular do IBS e da CBS, observado o disposto no
                  art. 151 da Lei Complementar nº …, de 2024.
                </td>
                <td>Art. 151, I, II</td>
                <td>
                  Art. 151. Ficam reduzidas a zero as alíquotas do IBS e da CBS
                  incidentes sobre a prestação de serviços de pesquisa e
                  desenvolvimento por Instituição Científica, Tecnológica e de
                  Inovação - ICT sem fins lucrativos para:
                  <br /> I - a administração pública direta, autarquias e
                  fundações públicas; ou
                  <br /> II - contribuinte sujeito ao regime regular do IBS e da
                  CBS.
                </td>
              </tr>
              <tr>
                <td>200012</td>
                <td>
                  Venda de automóveis de passageiros de fabricação nacional de,
                  no mínimo, 4 (quatro) portas, inclusive a de acesso ao
                  bagageiro, quando adquiridos por motoristas profissionais que
                  exerçam, comprovadamente, em automóvel de sua propriedade,
                  atividade de condutor autônomo de passageiros, na condição de
                  titular de autorização, permissão ou concessão do poder
                  público, e que destinem o automóvel à utilização na categoria
                  de aluguel (táxi), ou por pessoas com deficiência física,
                  visual, auditiva, deficiência mental severa ou profunda,
                  transtorno do espectro autista, com prejuízos na <br />{" "}
                  comunicação social e em padrões restritos ou repetitivos de
                  comportamento de nível moderado ou grave, nos termos da
                  legislação relativa à matéria, observado o disposto no art.
                  144 da Lei Complementar nº …, de 2024.
                </td>
                <td>Art. 144</td>
                <td>
                  Art. 144. Ficam reduzidas a zero as alíquotas do IBS e da CBS
                  incidentes sobre a venda de automóveis de passageiros de
                  fabricação nacional de, no mínimo, 4 (quatro) portas,
                  inclusive a de acesso ao bagageiro, quando adquiridos por
                </td>
              </tr>
              <tr>
                <td>200013</td>
                <td>
                  Vendas de produtos destinados à alimentação humana
                  relacionados no Anexo I da Lei Complementar nº…, de 2024,, com
                  a especificação das respectivas classificações da NCM/SH, que
                  compõem a Cesta Básica Nacional de Alimentos, criada nos
                  termos do art. 8º da Emenda Constitucional nº 132, de 20 de
                  dezembro de 2023, observado o art. 120 da Lei Complementar nº
                  …., de 2024.
                </td>
                <td>Art. 120</td>
                <td>
                  Art. 120. Ficam reduzidas a zero as alíquotas do IBS e da CBS
                  incidentes sobre as vendas de produtos destinados à
                  alimentação humana relacionados no Anexo I, com a
                  especificação das respectivas classificações da NCM/SH, que
                  compõem a Cesta Básica Nacional de Alimentos, criada nos
                  termos do art. 8º da Emenda Constitucional nº 132, de 20 de
                  dezembro de 2023.
                </td>
              </tr>
              <tr>
                <td>200014</td>
                <td>
                  Aquisições de máquinas, de aparelhos, de instrumentos, de
                  equipamentos, de matérias-primas, de produtos intermediários e
                  de materiais de embalagem realizadas entre empresas
                  autorizadas a operar em zonas de processamento de exportação,
                  observado o art. 100 da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 101, I, II</td>
                <td>
                  Art. 101. Ficam reduzidas a zero as alíquotas do IBS e da CBS
                  incidentes sobre os serviços de transporte:
                  <br /> I - dos bens de que tratam os arts. 97 e 98, até as
                  zonas de processamento de exportação; e<br /> II - dos bens
                  exportados a partir das zonas de processamento de exportação.
                </td>
              </tr>
              <tr>
                <td>200015</td>
                <td>
                  Operações de resseguro e retrocessão, desde que praticadas
                  entre sociedades seguradoras e resseguradores contribuintes do
                  IBS e da CBS, ficam sujeitas à incidência à alíquota zero,
                  inclusive quando os prêmios de resseguro e retrocessão forem
                  cedidos ao exterior, observado o art. 216 da Lei Complementar
                  nº …., de 2024.
                </td>
                <td>Art. 216, § 4º</td>
                <td>
                  Art. 216.§ 4º As operações de resseguro e retrocessão, desde
                  que praticadas entre sociedades seguradoras e resseguradores
                  contribuintes do IBS e da CBS, ficam sujeitas à incidência à
                  alíquota zero, inclusive quando os prêmios de resseguro e
                  retrocessão forem cedidos ao exterior.
                </td>
              </tr>
              <tr>
                <td>200016</td>
                <td>
                  Operação praticada por sociedades cooperativas optantes por
                  regime específico do IBS e CBS, quando o associado destinar
                  bem ou serviço à cooperativa de que participa, e  a
                  cooperativa fornecer bem ou serviço ao associado sujeito ao
                  regime regular do IBS e da CBS, observado o art. 270 da Lei
                  Complementar nº …., de 2024.
                </td>
                <td>Art. 270, I, II</td>
                <td>
                  Art. 270. As sociedades cooperativas poderão optar por regime
                  específico do IBS e da CBS no qual ficam reduzidas a zero as
                  alíquotas do IBS e da CBS incidentes na operação em que:
                  <br /> I - o associado destina bem ou serviço para a
                  cooperativa de que participa; e<br /> II - a cooperativa
                  fornece bem ou serviço a associado sujeito ao regime regular
                  do IBS e da CBS.
                </td>
              </tr>
              <tr>
                <td>200017</td>
                <td>
                  Operações relacionadas ao FGTS, considerando aquelas
                  necessárias à aplicação da Lei nº 8.036, de 1990, realizadas
                  pelo Conselho Curador ou Secretaria Executiva do FGTS,
                  observado o art. 204 da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 204, § 3º, I</td>
                <td>
                  Art. 204.§ 2º As operações relacionadas ao FGTS são aquelas
                  necessárias à aplicação da Lei nº 8.036, de 11 de maio de
                  1990, realizadas:
                  <br /> I - pelo agente operador do FGTS;
                  <br /> § 3º Ficam sujeitas:
                  <br /> I - à alíquota zero do IBS e da CBS, as operações
                  previstas no inciso I do § 2º;
                </td>
              </tr>
              <tr>
                <td>200018</td>
                <td>
                  Serviços de transporte público coletivo de passageiros
                  ferroviário e hidroviário urbanos, semiurbanos e
                  metropolitanos, observado o art. 284 da Lei Complementar nº
                  …., de 2024.
                </td>
                <td>Art. 284, I</td>
                <td>
                  Art. 284. Em relação aos serviços de transporte público
                  coletivo de passageiros ferroviário e hidroviário urbanos,
                  semiurbanos e metropolitanos: <br /> I - ficam reduzidas em
                  100% (cem por cento) as alíquotas do IBS e da CBS incidentes
                  sobre o fornecimento desses serviços;
                </td>
              </tr>
              <tr>
                <td>200019</td>
                <td>
                  Operação originada fora da Zona Franca de Manaus que destine
                  bem material industrializado de origem nacional a contribuinte
                  estabelecido na Zona Franca de Manaus que seja habilitado nos
                  termos do art. 440 da Lei Complementar nº …, de 2024, e
                  sujeito ao regime regular do IBS e da CBS ou optante pelo
                  regime do Simples Nacional de que trata o art. 12 da Lei
                  Complementar nº 123, de 2006, observado o art. 442 da Lei
                  Complementar nº …., de 2024. 
                </td>
                <td>Art. 442</td>
                <td>
                  Art. 442. Ficam reduzidas a zero as alíquotas do IBS e da CBS
                  incidentes sobre operação originada fora da Zona Franca de
                  Manaus que destine bem material industrializado de origem
                  nacional a contribuinte estabelecido na Zona Franca de Manaus
                  que seja
                </td>
              </tr>
              <tr>
                <td>200020</td>
                <td>
                  Operação originada fora das Áreas de Livre Comércio que
                  destine bem material industrializado de origem nacional a
                  contribuinte estabelecido nas Áreas de Livre Comércio que seja
                  habilitado nos termos do art. 456 da Lei Complementar nº …, de
                  2024, e sujeito ao regime regular do IBS e da CBS ou optante
                  pelo regime do Simples Nacional de que trata o art. 12 da Lei
                  Complementar nº 123, de 2006, observado o art. 458 da Lei
                  Complementar nº …., de 2024. 
                </td>
                <td>Art. 458</td>
                <td>
                  Art. 458. Ficam reduzidas a zero as alíquotas do IBS e da CBS
                  incidentes sobre operação originada fora das áreas de livre
                  comércio que destine bem material industrializado de origem
                  nacional a contribuinte estabelecido nas áreas de livre
                  comércio que seja:
                </td>
              </tr>
              <tr>
                <td>200021</td>
                <td>
                  Importador dos serviços financeiros seja contribuinte do IBS e
                  da CBS sujeito ao regime regular e tenha direito de
                  apropriação de créditos desses tributos na aquisição do mesmo
                  serviço financeiro no País, de acordo com o disposto neste
                  Capítulo, será aplicada alíquota zero na importação, e não
                  serão apropriados créditos do IBS e da CBS, observado o art.
                  225 da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 225, § 1º, II</td>
                <td>
                  Art. 225.§ 1º Na importação de serviços financeiros:II - nas
                  hipóteses em que o importador dos serviços financeiros seja
                  contribuinte do IBS e da CBS sujeito ao regime regular e tenha
                  direito de dedução da base de cálculo ou de apropriação de
                  créditos desses tributos na aquisição do mesmo serviço
                  financeiro no País, de acordo com o disposto neste Capítulo,
                  será aplicada alíquota zero na importação.
                </td>
              </tr>
              <tr>
                <td>200022</td>
                <td>
                  Importador dos serviços financeiros seja contribuinte que
                  realize as operações de que tratam os incisos I a V do caput
                  do art. 177, será aplicada alíquota zero na importação, sem
                  prejuízo da manutenção do direito de dedução dessas despesas
                  da base de cálculo do IBS e da CBS, segundo o disposto no art.
                  185 desta Lei Complementar, observado o art. 225 da Lei
                  Complementar nº …., de 2024.
                </td>
                <td>Art. 225 § 1º, III</td>
                <td>
                  Art. 225.§ 1º Na importação de serviços financeiros:III -
                  nas hipóteses em que o importador dos serviços financeiros
                  seja contribuinte que realize as operações de que tratam os
                  incisos I a V do caput do art. 177, será aplicada alíquota
                  zero na importação, sem prejuízo da manutenção do direito de
                  dedução dessas despesas da base de cálculo do IBS e da CBS,
                  segundo o disposto no art. 185 desta Lei Complementar. 
                </td>
              </tr>
              <tr>
                <td>Alíquota zero apenas CBS e reduzida em 60% para IBS</td>
                <td>200023</td>
                <td>
                  Fornecimento dos serviços de educação relacionados ao Programa
                  Universidade para Todos (Prouni), instituído pela Lei nº
                  11.096, de 13 de janeiro de 2005.
                </td>
                <td>Art. 307</td>
                <td>
                  Art. 307. Fica reduzida a zero a alíquota da CBS incidente
                  sobre o fornecimento de serviços de educação de ensino
                  superior por instituição privada de ensino, com ou sem fins
                  lucrativos, durante o período de adesão e vinculação ao
                  Programa Universidade para Todos - Prouni, instituído pela Lei
                  no 11.096, de 13 de janeiro de 2005.
                </td>
              </tr>
              <tr>
                <td>Alíquota reduzida em 80%</td>
                <td>200024</td>
                <td>
                  Locação de imóveis localizados nas zonas reabilitadas, pelo
                  prazo de 5 (cinco) anos, contado da data de expedição do
                  habite-se, e relacionados a projetos de reabilitação urbana de
                  zonas históricas e de áreas críticas de recuperação e
                  reconversão urbanística dos Municípios ou do Distrito Federal,
                  a serem delimitadas por lei municipal ou distrital, observado
                  o art. 153 da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 153, parágrafo único</td>
                <td>
                  Art. 153. Observado o disposto neste Capítulo, ficam reduzidas
                  em 60% (sessenta por cento) as alíquotas do IBS e da CBS sobre
                  operações relacionadas a projetos de reabilitação urbana de
                  zonas históricas e de áreas críticas de recuperação e
                  reconversão urbanística dos municípios ou do Distrito Federal,
                  a serem delimitadas por lei municipal ou distrital.
                  <br /> Parágrafo único. Na hipótese de locação de imóveis
                  prevista no inciso VI do art. 157, a redução de alíquotas de
                  que trata o caput deste artigo será de 80% (oitenta por
                  cento).
                </td>
              </tr>
              <tr>
                <td rowspan="17">Alíquota reduzida em 60%</td>
                <td>200025</td>
                <td>
                  Fornecimento dos serviços de educação relacionados no Anexo II
                  da Lei Complementar nº …, de 2024, com a especificação das
                  respectivas classificações da Nomenclatura Brasileira de
                  Serviços, Intangíveis e Outras Operações que Produzam
                  Variações no Patrimônio (NBS), observado o art. 124 da Lei
                  Complementar nº …., de 2024.
                </td>
                <td>Art. 124</td>
                <td>
                  Art. 124. Ficam reduzidas em 60% (sessenta por cento) as
                  alíquotas do IBS e da CBS incidentes sobre o fornecimento dos
                  serviços de educação relacionados no Anexo II, com a
                  especificação das respectivas classificações da NBS.
                </td>
              </tr>
              <tr>
                <td>200026</td>
                <td>
                  Fornecimento dos serviços de saúde humana relacionados no
                  Anexo III da Lei Complementar nº …, de 2024, com a
                  especificação das respectivas classificações da NBS, observado
                  o art. 125 da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 125</td>
                <td>
                  Art. 125. Ficam reduzidas em 60% (sessenta por cento) as
                  alíquotas do IBS e da CBS incidentes sobre o fornecimento dos
                  serviços de saúde humana relacionados no Anexo III, com a
                  especificação das respectivas classificações da NBS
                  <br />{" "}
                </td>
              </tr>
              <tr>
                <td>200027</td>
                <td>
                  Venda dos dispositivos médicos relacionados no Anexo IV da Lei
                  Complementar nº …, de 2024, com a especificação das
                  respectivas classificações da NCM/SH, observado o art. 126 da
                  Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 126</td>
                <td>
                  Art. 126 Ficam reduzidas em 60% (sessenta por cento) as
                  alíquotas do IBS e da CBS incidentes sobre a venda dos
                  dispositivos médicos relacionados no Anexo IV, com a
                  especificação das respectivas classificações da NCM/SH.
                </td>
              </tr>
              <tr>
                <td>200028</td>
                <td>
                  Fornecimento dos dispositivos de acessibilidade próprios para
                  pessoas com deficiência relacionados no Anexo V da Lei
                  Complementar nº …, de 2024, com a especificação das
                  respectivas classificações da NCM/SH, observado o art. 127 da
                  Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 127</td>
                <td>
                  Art. 127. Ficam reduzidas em 60% (sessenta por cento) as
                  alíquotas do IBS e da CBS incidentes sobre o fornecimento dos
                  dispositivos de acessibilidade próprios para pessoas com
                  deficiência relacionados no Anexo V, com a especificação das
                  respectivas classificações da NCM/SH
                </td>
              </tr>
              <tr>
                <td>200029</td>
                <td>
                  Fornecimento dos medicamentos registrados na Anvisa ou
                  produzidos por farmácias de manipulação, ressalvados os
                  medicamentos sujeitos à alíquota zero de que trata o art. 141
                  da Lei Complementar nº …, de 2024, observado o art. 128 da Lei
                  Complementar nº …., de 2024.
                </td>
                <td>Art. 128</td>
                <td>
                  Art. 128. Ficam reduzidas em 60% (sessenta por cento) as
                  alíquotas do IBS e da CBS incidentes sobre o fornecimento dos
                  medicamentos registrados na Anvisa ou produzidos por farmácias
                  de manipulação, ressalvados os medicamentos sujeitos à
                  alíquota zero de que trata o art. 141.
                </td>
              </tr>
              <tr>
                <td>200030</td>
                <td>
                  Fornecimento dos alimentos destinados ao consumo humano
                  relacionados no Anexo VII da Lei Complementar nº …, de 2024,
                  com a especificação das respectivas classificações da NCM/SH,
                  observado o art. 130 da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 130</td>
                <td>
                  Art. 130. Ficam reduzidas em 60% (sessenta por cento) as
                  alíquotas do IBS e da CBS incidentes sobre o fornecimento dos
                  alimentos destinados ao consumo humano relacionados no Anexo
                  VIII, com a especificação das respectivas classificações da
                  NCM/SH
                </td>
              </tr>
              <tr>
                <td>200031</td>
                <td>
                  Fornecimento dos produtos de higiene pessoal e limpeza
                  relacionados no Anexo VIII da Lei Complementar nº …, de 2024,
                  com a especificação das respectivas classificações da NCM/SH,
                  observado o art. 131 da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 131</td>
                <td>
                  Art. 131. Ficam reduzidas em 60% (sessenta por cento) as
                  alíquotas do IBS e da CBS incidentes sobre o fornecimento dos
                  produtos de higiene pessoal e limpeza relacionados no Anexo
                  IX, com a especificação das respectivas classificações da
                  NCM/SH.
                </td>
              </tr>
              <tr>
                <td>200032</td>
                <td>
                  Fornecimento de produtos agropecuários, aquícolas, pesqueiros,
                  florestais e extrativistas vegetais in natura, observado o
                  art. 132 da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 132</td>
                <td>
                  Art. 132. Ficam reduzidas em 60% (sessenta por cento) as
                  alíquotas do IBS e da CBS incidentes sobre o fornecimento de
                  produtos agropecuários, aquícolas, pesqueiros, florestais e
                  extrativistas vegetais in natura.
                </td>
              </tr>
              <tr>
                <td>200033</td>
                <td>
                  Fornecimento dos insumos agropecuários e aquícolas
                  relacionados no Anexo IX da Lei Complementar nº …, de 2024,
                  com a especificação das respectivas classificações da NCM/SH e
                  da NBS, observado o art. 133 da Lei Complementar nº …., de
                  2024.
                </td>
                <td>Art. 133</td>
                <td>
                  Art. 133. Ficam reduzidas em 60% (sessenta por cento) as
                  alíquotas do IBS e da CBS incidentes sobre o fornecimento dos
                  insumos agropecuários e aquícolas relacionados no Anexo IX,
                  com a especificação das respectivas classificações da NCM/SH e
                  da NBS.
                </td>
              </tr>
              <tr>
                <td>200034</td>
                <td>
                  Fornecimento dos serviços e o licenciamento ou cessão dos
                  direitos relacionados no Anexo X da Lei Complementar nº …, de
                  2024, com a especificação das respectivas classificações da
                  NBS, quando destinados às seguintes produções nacionais
                  artísticas, culturais, de eventos, jornalísticas e
                  audiovisuais: espetáculos teatrais, circenses e de dança,
                  shows musicais, desfiles carnavalescos ou folclóricos, eventos
                  acadêmicos e científicos, como congressos, conferências e
                  simpósios, feiras de negócios, exposições, feiras e mostras
                  culturais, artísticas e literárias; programas de auditório ou
                  jornalísticos, filmes, documentários, séries, novelas,
                  entrevistas e clipes musicais, observado o art. 134 da Lei
                  Complementar nº …., de 2024.
                </td>
                <td>Art. 134</td>
                <td>
                  Art. 134. Ficam reduzidas em 60% (sessenta por cento) as
                  alíquotas do IBS e da CBS incidentes sobre a prestação dos
                  serviços e o licenciamento ou cessão dos direitos relacionados
                  no Anexo XI, com a especificação das respectivas
                  classificações da NBS, quando destinados às seguintes 
                </td>
              </tr>
              <tr>
                <td>200035</td>
                <td>
                  Fornecimento dos seguintes serviços de comunicação
                  institucional à administração pública direta, autarquias e
                  fundações públicas: serviços direcionados ao planejamento,
                  criação, programação e manutenção de páginas eletrônicas da
                  administração pública, ao monitoramento e gestão de suas redes
                  sociais e à otimização de páginas e canais digitais para
                  mecanismos de buscas e produção de mensagens, infográficos,
                  painéis interativos e conteúdo institucional, serviços de
                  relações com a imprensa, que reúnem estratégias
                  organizacionais para promover e reforçar a comunicação dos
                  órgãos e das entidades contratantes com seus públicos de
                  interesse, por meio da interação com profissionais da
                  imprensa, e serviços de relações públicas, que compreendem o
                  esforço de comunicação planejado, coeso e contínuo que tem por
                  objetivo estabelecer adequada percepção da atuação e dos
                  objetivos institucionais, a partir do estímulo à compreensão
                  mútua e da manutenção de padrões de relacionamento e fluxos de
                  informação entre os órgãos e as entidades contratantes e seus
                  públicos de interesse, no País e no exterior, observado o art.
                  135 da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 135</td>
                <td>
                  Art. 135. Ficam reduzidas em 60% (sessenta por cento) as
                  alíquotas do IBS e da CBS incidentes sobre a prestação dos
                  seguintes serviços de comunicação institucional à
                  administração pública direta, autarquias e fundações públicas
                </td>
              </tr>
              <tr>
                <td>200036</td>
                <td>
                  Operações relacionadas às seguintes atividades desportivas:
                  fornecimento de serviço de educação desportiva, classificado
                  no código 1.2205.12.00 da NBS, e gestão e exploração do
                  desporto por associações e clubes esportivos filiados ao órgão
                  estadual ou federal responsável pela coordenação dos
                  desportos, inclusive por meio de venda de ingressos para
                  eventos desportivos, fornecimento oneroso ou não de bens e
                  serviços, inclusive ingressos, por meio de programas de
                  sócio-torcedor, cessão dos direitos desportivos dos atletas e
                  transferência de atletas para outra entidade desportiva ou seu
                  retorno à atividade em outra entidade desportiva, observado o
                  art. 136 da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 136</td>
                <td>
                  Art. 136. Ficam reduzidas em 60% (sessenta por cento) as
                  alíquotas do IBS e da CBS incidentes sobre as seguintes
                  operações relacionadas a atividades desportivas:
                </td>
              </tr>
              <tr>
                <td>200037</td>
                <td>
                  Fornecimento à administração pública direta, autarquias e
                  fundações púbicas dos serviços e dos bens relativos à
                  soberania e à segurança nacional, à segurança da informação e
                  à segurança cibernética relacionados no Anexo XI da Lei
                  Complementar nº…., de 2024, com a especificação das
                  respectivas classificações da NBS e da NCM/SH, observado o
                  art. 137 da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 137 I</td>
                <td>
                  Art. 137. Ficam reduzidas em 60% (sessenta por cento) as
                  alíquotas do IBS e da CBS sobre:
                  <br /> I - o fornecimento, à administração pública direta,
                  autarquias e fundações púbicas, dos serviços e dos bens
                  relativos a soberania e segurança nacional, segurança da
                  informação e segurança cibernética relacionados no Anexo XII,
                  com a especificação das respectivas classificações da NBS e da
                  NCM/SH; e
                </td>
              </tr>
              <tr>
                <td>200038</td>
                <td>
                  Operações e prestações de serviços de segurança da informação
                  e segurança cibernética desenvolvidos por sociedade que tenha
                  sócio brasileiro com o mínimo de 20% (vinte por cento) do seu
                  capital social, relacionados no Anexo XI da Lei Complementar
                  nº…., de 2024, com a especificação das respectivas
                  classificações da NBS e da NCM/SH, observado o art. 137 da Lei
                  Complementar nº …., de 2024.
                </td>
                <td>Art. 137 II</td>
                <td>
                  Art. 137. Ficam reduzidas em 60% (sessenta por cento) as
                  alíquotas do IBS e da CBS sobre:
                  <br /> II - operações e prestações de serviços de segurança da
                  informação e segurança cibernética desenvolvidos por sociedade
                  que tenha sócio brasileiro com o mínimo de 20% (vinte por
                  cento) do seu capital social, relacionados no Anexo XII, com a
                  especificação das respectivas classificações da NBS e da
                  NCM/SH.
                </td>
              </tr>
              <tr>
                <td>200039</td>
                <td>
                  Operações relacionadas a projetos de reabilitação urbana de
                  zonas históricas e de áreas críticas de recuperação e
                  reconversão urbanística dos Municípios ou do Distrito Federal,
                  a serem delimitadas por lei municipal ou distrital, observado
                  o art. 153 da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 153</td>
                <td>
                  Art. 153. Observado o disposto neste Capítulo, ficam reduzidas
                  em 60% (sessenta por cento) as alíquotas do IBS e da CBS sobre
                  operações relacionadas a projetos de reabilitação urbana de
                  zonas históricas e de áreas críticas de recuperação e
                  reconversão urbanística dos municípios ou do Distrito Federal,
                  a serem delimitadas por lei municipal ou distrital.
                </td>
              </tr>
              <tr>
                <td>200040</td>
                <td>
                  Planos de assistência à saúde, observado o art. 230 da Lei
                  Complementar nº …., de 2024.
                </td>
                <td>Art. 230</td>
                <td>
                  Art. 230. A alíquota de IBS e CBS no regime específico de
                  planos de assistência à saúde é nacionalmente uniforme e
                  corresponderá às alíquotas de referência de cada esfera
                  federativa, reduzidas em 60% (sessenta por cento).
                </td>
              </tr>
              <tr>
                <td>200041</td>
                <td>
                  Operações de locação, cessão onerosa e arrendamento de bens
                  imóveis, observado o art. 257 da Lei Complementar nº …., de
                  2024.
                </td>
                <td>Art. 257, parágrafo único</td>
                <td>
                  Art. 257. As alíquotas do IBS e da CBS relativas às operações
                  de que trata este capítulo ficam reduzidas em 40% (quarenta
                  por cento).
                  <br /> Parágrafo único. As alíquotas do IBS e da CBS relativas
                  às operações de locação, cessão onerosa e arrendamento de bens
                  imóveis ficam reduzidas em 60% (sessenta por cento).
                </td>
              </tr>
              <tr>
                <td rowspan="2">Alíquota reduzida em 40%</td>
                <td>200042</td>
                <td>
                  Operações com bens imóveis, exceto às operações de locação,
                  cessão onerosa e arrendamento de bens imóveis, observado o
                  art. 257 da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 257</td>
                <td>
                  Art. 257. As alíquotas do IBS e da CBS relativas às operações
                  de que trata este capítulo ficam reduzidas em 40% (quarenta
                  por cento).
                </td>
              </tr>
              <tr>
                <td>200043</td>
                <td>
                  Serviços de transporte coletivo de passageiros aéreo regional,
                  observado o art. 286 da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 286</td>
                <td>
                  Art. 286. Em relação aos serviços de transporte coletivo de
                  passageiros aéreo regional, as alíquotas do IBS e da CBS
                  incidentes sobre o fornecimento desses serviços ficam
                  reduzidas em 40% (quarenta por cento).
                </td>
              </tr>
              <tr>
                <td rowspan="2">Alíquota reduzida em 30%</td>
                <td>200044</td>
                <td>
                  Prestação de serviços das seguintes profissões intelectuais de
                  natureza científica, literária ou artística, submetidas à
                  fiscalização por conselho profissional: administradores,
                  advogados, arquitetos e urbanistas, assistentes sociais,
                  bibliotecários, biólogos, contabilistas, economistas,
                  economistas domésticos, profissionais de educação física,
                  engenheiros e agrônomos, estatísticos, médicos veterinários e
                  zootecnistas, museólogos, químicos, profissionais de relações
                  públicas, técnicos industriais e técnicos agrícolas, observado
                  o art. 122 da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 122, I a XVIII</td>
                <td>
                  Art. 122. Ficam reduzidas em 30% (trinta por cento) as
                  alíquotas do IBS e da CBS incidentes sobre a prestação de
                  serviços das seguintes profissões intelectuais de natureza
                  científica, literária ou artística, submetidas à fiscalização
                  por conselho profissional:
                  <br /> I - administradores;
                  <br /> II - advogados;
                  <br /> III - arquitetos e urbanistas;
                  <br /> IV - assistentes sociais;
                  <br /> V - bibliotecários;
                  <br /> VI - biólogos;
                  <br /> VII - contabilistas;
                  <br /> VIII - economistas;
                  <br /> IX - economistas domésticos;
                  <br /> X - profissionais de educação física;
                  <br /> XI - engenheiros e agrônomos;
                  <br /> XII - estatísticos;
                  <br /> XIII - médicos veterinários e zootecnistas;
                  <br /> XIV - museólogos;
                  <br /> XV - químicos;
                  <br /> XVI - profissionais de relações públicas;
                  <br /> XVII - técnicos industriais; e<br /> XVIII - técnicos
                  agrícolas.
                </td>
              </tr>
              <tr>
                <td>200045</td>
                <td>
                  Planos de assistência à saúde de animais domésticos, observado
                  o art. 236 da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 236</td>
                <td>
                  Art. 236. Os planos de assistência à saúde de animais
                  domésticos ficam sujeitos ao disposto nos arts. 228 a 235
                  desta Lei Complementar, com exceção das alíquotas aplicáveis,
                  que serão nacionalmente uniformes e corresponderão à soma das
                  alíquotas de referência de cada esfera federativa, reduzidas
                  em 30% (trinta por cento), vedado o crédito ao adquirente.
                </td>
              </tr>
              <tr>
                <td rowspan="2">400</td>
                <td rowspan="2">Isenção</td>
                <td>400001</td>
                <td>
                  Fornecimento de serviços de transporte público coletivo de
                  passageiros rodoviário e metroviário de caráter urbano,
                  semiurbano e metropolitano, sob regime de autorização,
                  permissão ou concessão pública, observado o art. 152 da Lei
                  Complementar nº …., de 2024.
                </td>
                <td>Art. 152</td>
                <td>
                  Art. 152. Fica isento do IBS e da CBS o fornecimento de
                  serviços de transporte público coletivo de passageiros
                  rodoviário e metroviário de caráter urbano, semiurbano e
                  metropolitano, sob regime de autorização, permissão ou
                  concessão pública
                </td>
              </tr>
              <tr>
                <td>400002</td>
                <td class="tg-ktyi">
                  Operações relacionadas aos fundos garantidores e executores de
                  políticas públicas, inclusive de habitação, previstos em lei,
                  assim entendidas os serviços prestados ao fundo pelo seu
                  agente operador e por entidade encarregada da sua
                  administração, observado o art. 204 da Lei Complementar nº ….,
                  de 2024.
                </td>
                <td>Art. 204, § 4º</td>
                <td>
                  Art. 204.§ 4º Ficam isentas as operações relacionadas aos
                  demais fundos garantidores e executores de políticas públicas,
                  inclusive de habitação, previstos em lei, assim entendidos os
                  serviços prestados ao fundo pelo seu agente operador e por
                  entidade encarregada da sua administração.
                </td>
              </tr>
              <tr>
                <td rowspan="18">410</td>
                <td rowspan="18">Imunidade e não incidência</td>
                <td>410001</td>
                <td>
                  Transferências entre estabelecimentos pertencentes ao mesmo
                  contribuinte
                </td>
                <td>Art. 7º, II</td>
                <td>
                  Art. 7ºII - transferência de bens entre estabelecimentos
                  pertencentes ao mesmo contribuinte, observada a
                  obrigatoriedade de emissão de documento fiscal eletrônico, nos
                  termos do inciso II do § 2º do art. 44 desta Lei Complementar;
                </td>
              </tr>
              <tr>
                <td>410002</td>
                <td>
                  Exportações de bens e serviços, observado o art. 8º da Lei
                  Complementar nº …., de 2024.
                </td>
                <td>Art. 8º</td>
                <td>
                  Art. 8º São imunes ao IBS e à CBS as exportações de bens e de
                  serviços, nos termos do Capítulo V deste Título
                </td>
              </tr>
              <tr>
                <td>410003</td>
                <td>
                  Fornecimentos realizados pela União, pelos Estados, pelo
                  Distrito Federal e pelos Municípios, observado o art. 9º e §
                  1º do art. 9º da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 9º, I e § 1º</td>
                <td>
                  Art. 9ºI - realizados pela União, pelos Estados, pelo
                  Distrito Federal e pelos Municípios;§ 1º A imunidade prevista
                  no inciso I do caput deste artigo é extensiva às autarquias e
                  às fundações instituídas e mantidas pelo poder público e à
                  empresa pública prestadora de serviço postal, bem como:
                </td>
              </tr>
              <tr>
                <td>410004</td>
                <td>
                  Fornecimentos realizados por entidades religiosas e templos de
                  qualquer culto, inclusive suas organizações assistenciais e
                  beneficentes, observado o art. 9º da Lei Complementar nº ….,
                  de 2024.
                </td>
                <td>Art. 9º, II</td>
                <td>
                  Art. 9ºII - realizados por entidades religiosas e templos de
                  qualquer culto, inclusive suas organizações assistenciais e
                  beneficentes
                </td>
              </tr>
              <tr>
                <td>410005</td>
                <td>
                  Fornecimentos realizados por partidos políticos, inclusive
                  suas fundações, entidades sindicais dos trabalhadores e
                  instituições de educação e de assistência social, sem fins
                  lucrativos, observado o art. 9º da Lei Complementar nº …., de
                  2024.
                </td>
                <td>Art. 9º, III</td>
                <td>
                  Art. 9ºIII - realizados por partidos políticos, inclusive
                  suas fundações, entidades sindicais dos trabalhadores e
                  instituições de educação e de assistência social, sem fins
                  lucrativos
                </td>
              </tr>
              <tr>
                <td>410006</td>
                <td>
                  Fornecimentos de livros, jornais, periódicos e do papel
                  destinado a sua impressão, observado o art. 9º da Lei
                  Complementar nº …., de 2024.
                </td>
                <td>Art. 9º, IV</td>
                <td>
                  Art. 9ºIV - de livros, jornais, periódicos e o papel
                  destinado a sua impressão;
                </td>
              </tr>
              <tr>
                <td>410007</td>
                <td>
                  Fornecimentos de fonogramas e videofonogramas musicais
                  produzidos no Brasil contendo obras musicais ou literomusicais
                  de autores brasileiros e/ou obras em geral interpretadas por
                  artistas brasileiros, bem como os suportes materiais ou
                  arquivos digitais que os contenham, salvo na etapa de
                  replicação industrial de mídias ópticas de leitura a laser,
                  observado o art. 9º da Lei Complementar nº …., de 2024.
                </td>
                <td>Art. 9º, V</td>
                <td>
                  Art. 9ºV - de fonogramas e videofonogramas musicais
                  produzidos no Brasil contendo obras musicais ou literomusicais
                  de autores brasileiros e/ou obras em geral interpretadas por
                  artistas brasileiros bem como os suportes materiais ou
                  arquivos digitais que os contenham, salvo na etapa de
                  replicação industrial de mídias ópticas de leitura a laser;
                </td>
              </tr>
              <tr>
                <td>410008</td>
                <td>
                  Fornecimentos de serviço de comunicação nas modalidades de
                  radiodifusão sonora e de sons e imagens de recepção livre e{" "}
                  <br /> gratuita, observado o art. 9º da Lei Complementar nº
                  …., de 2024.
                </td>
                <td>Art. 9º, VI</td>
                <td>
                  Art. 9ºVI - as prestações de serviço de comunicação nas
                  modalidades de radiodifusão sonora e de sons e imagens de
                  recepção livre e gratuita;
                </td>
              </tr>
              <tr>
                <td>410009</td>
                <td>
                  Fornecimentos de ouro, quando definido em lei como ativo
                  financeiro ou instrumento cambial, observado o art. 9º da Lei
                  Complementar nº …., de 2024.
                </td>
                <td>Art. 9º, VII</td>
                <td>
                  Art. 9ºVII - as operações com ouro, quando definido em lei
                  como ativo financeiro ou instrumento cambial.
                </td>
              </tr>
              <tr>
                <td>410010</td>
                <td>
                  Operações já tributadas com pagamento anterior ao fornecimento
                  dos bens e serviços.
                </td>
                <td>Art, 10, I</td>
                <td>
                  Art. 10. Considera-se ocorrido o fato gerador do IBS e da CBS
                  no momento: <br /> I - do fornecimento ou do pagamento, mesmo
                  que parcial, o que ocorrer primeiro, nas operações com bens ou
                  com serviços, ainda que de execução continuada ou fracionada;
                </td>
              </tr>
              <tr>
                <td>410011</td>
                <td>Doações</td>
                <td>Art. 7º, VIII</td>
                <td>
                  Art. 7ºVIII - doações sem contraprestação em benefício do
                  doador;
                </td>
              </tr>
              <tr>
                <td>410012</td>
                <td>
                  Aquisição de bem móvel com crédito presumido sob condição de
                  revenda realizada.
                </td>
                <td>Art. 166</td>
                <td>
                  Art. 166. O contribuinte de IBS e de CBS sujeito ao regime
                  regular poderá apropriar créditos presumidos dos referidos
                  tributos relativos às aquisições, para revenda, de bem móvel
                  usado de pessoa física que não seja contribuinte dos referidos
                  tributos ou que seja inscrita como MEI.
                </td>
              </tr>
              <tr>
                <td>410013</td>
                <td>
                  Exclusão da gorjeta na base de cálculo no fornecimento de
                  alimentação.
                </td>
                <td>Art. 273, I</td>
                <td>
                  Art. 273. A base de cálculo do IBS e da CBS é o valor da
                  operação de fornecimento de alimentação e das bebidas de que
                  trata o § 1º do art. 272 desta Lei Complementar.
                  <br /> Parágrafo único. Ficam excluídos da base de cálculo:
                  <br /> I - a gorjeta incidente no fornecimento de alimentação,
                  desde que repassada integralmente ao empregado, sem prejuízo
                  dos valores da gorjeta que forem retidos pelo empregador em
                  virtude de determinação legal;
                </td>
              </tr>
              <tr>
                <td>410014</td>
                <td>
                  Exclusão do valor de intermediação na base de cálculo no
                  fornecimento de alimentação.
                </td>
                <td>Art. 273, II</td>
                <td>
                  Art. 273. A base de cálculo do IBS e da CBS é o valor da
                  operação de fornecimento de alimentação e das bebidas de que
                  trata o § 1º do art. 272 desta Lei Complementar.
                  <br /> Parágrafo único. Ficam excluídos da base de cálculo:
                  <br /> II - os valores não repassados aos bares e restaurantes
                  pelo serviço de entrega e intermediação de pedidos de
                  alimentação e bebidas por plataforma digital.
                </td>
              </tr>
              <tr>
                <td>410015</td>
                <td>Fornecimento de produtor rural não contribuinte.</td>
                <td>Art. 159, § 4º</td>
                <td>
                  Art. 159. O produtor rural pessoa física ou jurídica que
                  auferir receita inferior a R$ 3.600.000,00 (três milhões e
                  seiscentos mil reais) no ano-calendário e o produtor rural
                  integrado não serão considerados contribuintes do IBS e da
                  CBS.
                  <br /> § 4º Os percentuais dos créditos presumidos do IBS e da
                  CBS corresponderão à proporção entre o valor referido no § 3º
                  deste artigo e o valor total dos bens e serviços fornecidos
                  pelos produtores rurais e produtores rurais integrados não
                  contribuintes.
                </td>
              </tr>
              <tr>
                <td>410016</td>
                <td>
                  Fornecimento por transportador autônomo não contribuinte.
                </td>
                <td>Art. 164, § 4º</td>
                <td>
                  Art. 164. O contribuinte de IBS e de CBS sujeito ao regime
                  regular poderá apropriar créditos presumidos dos referidos
                  tributos relativos às aquisições de serviço de transporte de
                  carga de transportador autônomo pessoa física que não seja
                  contribuinte dos referidos tributos ou que seja inscrito como
                  MEI.
                  <br /> § 4º Os percentuais dos créditos presumidos do IBS e da
                  CBS corresponderão à proporção entre o valor referido no § 3º
                  deste artigo e o valor total dos serviços fornecidos pelos
                  transportadores de que trata o § 3º deste artigo.
                </td>
              </tr>
              <tr>
                <td>410017</td>
                <td>
                  Tratado internacional (avaliar eventual isenção heterônoma)
                </td>
                <td>Sem referência</td>
                <td>Sem referência no PLP</td>
              </tr>
              <tr>
                <td>410018</td>
                <td>
                  Imunidade recíproca (avaliar a extenção em relação 1140 STF)
                </td>
                <td>CF, art. 150, VI, a</td>
                <td> </td>
              </tr>
              <tr>
                <td>510</td>
                <td>Diferimento</td>
                <td>510001</td>
                <td>
                  Operações, sujeitas a diferimento, com insumos agropecuários e
                  aquícolas destinados a produtor rural contribuinte.
                </td>
                <td>Art. 133, § 3º</td>
                <td>
                  Art. 133.§ 3º Fica diferido o recolhimento do IBS e da CBS{" "}
                  <br /> incidentes nas operações de que trata o caput deste
                  artigo, desde que o adquirente seja produtor rural pessoa
                  física ou jurídica sujeito ao regime regular do IBS e da CBS,
                  exceto a sociedade cooperativa que optar pelo regime de que
                  trata o art. 270 desta Complementar.
                </td>
              </tr>
              <tr>
                <td rowspan="12">550</td>
                <td rowspan="12">Suspensão</td>
                <td>550001</td>
                <td>
                  Exportações de bens materiais, observado o art. 86 da Lei
                  Complementar nº …., de 2024.
                </td>
                <td>Art. 86</td>
                <td>
                  Art. 86. Poderá ser suspenso o pagamento do IBS e da CBS no
                  fornecimento de bens materiais com o fim específico de
                  exportação a empresa comercial exportadora que atenda
                  cumulativamente os seguintes requisitos:
                </td>
              </tr>
              <tr>
                <td>550002</td>
                <td>Regime de Trânsito.</td>
                <td>Art. 88</td>
                <td>
                  Art. 88. Fica suspenso o pagamento do IBS e da CBS incidentes
                  na importação enquanto os bens materiais estiverem submetidos
                  ao regime aduaneiro especial de trânsito aduaneiro, em
                  qualquer de suas modalidades, observada a disciplina
                  estabelecida pela legislação aduaneira.
                </td>
              </tr>
              <tr>
                <td>550003</td>
                <td>Regimes de Depósito.</td>
                <td>Art. 89</td>
                <td>
                  Art. 89. Fica suspenso o pagamento do IBS e da CBS incidentes
                  na importação enquanto os bens materiais estiverem submetidos
                  a regime aduaneiro especial de depósito, observada a
                  disciplina estabelecida pela legislação aduaneira.
                </td>
              </tr>
              <tr>
                <td>550004</td>
                <td>Regimes de Permanência Temporária.</td>
                <td>Art. 91</td>
                <td>
                  Art. 91. Fica suspenso o pagamento do IBS e da CBS incidentes
                  na importação enquanto os bens materiais estiverem submetidos
                  a regime aduaneiro especial de permanência temporária no País
                  ou de saída temporária do País, observada a disciplina
                  estabelecida pela legislação aduaneira.
                </td>
              </tr>
              <tr>
                <td>550005</td>
                <td>Regimes de Aperfeiçoamento.</td>
                <td>Art. 93, § 5º, I a XVI</td>
                <td>
                  Art. 93. Fica suspenso o pagamento do IBS e da CBS incidentes
                  na importação enquanto os bens materiais estiverem submetidos
                  a regime aduaneiro especial de aperfeiçoamento, observada a
                  disciplina estabelecida na legislação aduaneira. <br /> § 5º
                  Os serviços submetidos ao regime aduaneiro especial de
                  aperfeiçoamento são aqueles direta e exclusivamente vinculados
                  à exportação de bem final ou associados à sua entrega no
                  exterior, limitando-se às seguintes atividades:
                  <br /> I - serviços de intermediação na distribuição de
                  mercadorias no exterior (comissão de agente);
                  <br /> II - serviços de seguro de cargas;
                  <br /> III - serviços de despacho aduaneiro;
                  <br /> IV - serviços de armazenagem de mercadorias;
                  <br /> V - serviços de transporte rodoviário, ferroviário,
                  aéreo, aquaviário ou multimodal de cargas;
                  <br /> VI - serviços de manuseio de cargas;
                  <br /> VII - serviços de manuseio de contêineres;
                  <br /> VIII - serviços de unitização ou desunitização de
                  cargas;
                  <br /> IX - serviços de consolidação ou desconsolidação
                  documental de cargas;
                  <br /> X - serviços de agenciamento de transporte de cargas;
                  <br /> XI - serviços de remessas expressas;
                  <br /> XII - serviços de pesagem e medição de cargas;
                  <br /> XIII - serviços de refrigeração de cargas;
                  <br /> XIV - arrendamento mercantil operacional ou locação de
                  contêineres;
                  <br /> XV - serviços de instalação e montagem de mercadorias
                  exportadas; e<br /> XVI - serviços de treinamento para uso de
                  mercadorias exportadas.
                </td>
              </tr>
              <tr>
                <td>550006</td>
                <td>
                  Regime Aduaneiro Especial Aplicável ao Setor de Petróleo e Gás
                  - Repetro.
                </td>
                <td>Seção VArt. 96</td>
                <td>
                  Seção V<br /> Do Regime Aduaneiro Especial Aplicável ao Setor
                  de Petróleo e Gás (Repetro)Art. 96. Observada a disciplina
                  estabelecida na legislação aduaneira, fica suspenso o
                  pagamento do IBS e da CBS nas seguintes operações: 
                </td>
              </tr>
              <tr>
                <td>550007</td>
                <td>Zona de Processamento de Exportação.</td>
                <td>Art. 97 e Art. 98</td>
                <td>
                  Art. 97. As importações ou as aquisições no mercado interno de
                  máquinas, de aparelhos, de instrumentos e de equipamentos
                  realizadas por empresa autorizada a operar em zonas de
                  processamento de exportação serão efetuadas com suspensão do
                  pagamento do IBS e da CBS.Art. 98. As importações ou as
                  aquisições no mercado interno de matérias-primas, de produtos
                  intermediários e de materiais de embalagem realizadas por
                  empresa autorizada a operar em zonas de processamento de
                  exportação serão efetuadas com suspensão do pagamento do IBS e
                  da CBS. 
                </td>
              </tr>
              <tr>
                <td>550008</td>
                <td>
                  Regime Tributário para Incentivo à Modernização e à Ampliação
                  da Estrutura Portuária - Reporto.
                </td>
                <td>Art. 103</td>
                <td>
                  Art. 103. Observada a disciplina estabelecida na legislação
                  específica, serão efetuadas com suspensão do pagamento do IBS
                  e da CBS as importações e as aquisições no <br /> mercado
                  interno de máquinas, equipamentos, peças de reposição e outros
                  bens realizadas diretamente pelos beneficiários do Regime
                  Tributário para Incentivo à Modernização e à Ampliação da
                  Estrutura Portuária (Reporto) e destinadas ao seu ativo
                  imobilizado para utilização exclusiva na execução de serviços
                  de:
                </td>
              </tr>
              <tr>
                <td>550009</td>
                <td>
                  Regime Especial de Incentivos para o Desenvolvimento da
                  Infraestrutura - Reidi.
                </td>
                <td>Art. 104</td>
                <td>
                  Art. 104. Observada a disciplina estabelecida na legislação
                  específica, serão efetuadas com suspensão do pagamento do IBS
                  e da CBS as importações e as aquisições no mercado interno de
                  máquinas, aparelhos, instrumentos e equipamentos, novos, e de
                  materiais de construção, realizadas diretamente pelos
                  beneficiários do Regime Especial de Incentivos para o
                  Desenvolvimento da Infraestrutura (Reidi) para utilização ou
                  incorporação em obras de infraestrutura destinadas ao ativo
                  imobilizado.
                </td>
              </tr>
              <tr>
                <td>550010</td>
                <td>Desoneração da aquisição de bens de capital.</td>
                <td>Art. 105</td>
                <td>
                  Art. 105. Ato conjunto do Poder Executivo da União e do Comitê
                  Gestor do IBS poderá definir hipóteses em que importações e
                  aquisições no mercado interno de bens de capital serão
                  realizadas com suspensão do pagamento do IBS e da CBS. 
                </td>
              </tr>
              <tr>
                <td>550011</td>
                <td>
                  Importação de bem material por indústria incentivada para
                  utilização na Zona Franca de Manaus.
                </td>
                <td>Art. 441</td>
                <td>
                  Art. 441. Fica suspensa a incidência do IBS e da CBS na
                  importação de bem material realizada por indústria incentivada
                  para utilização na Zona Franca de Manaus.
                </td>
              </tr>
              <tr>
                <td>550012</td>
                <td>Áreas de livre comércio.</td>
                <td>Art. 457</td>
                <td>
                  Art. 457. Fica suspensa a incidência do IBS e da CBS na
                  importação de bem material realizada por indústria habilitada
                  na forma do inciso II do caput do art. 456 e sujeita ao regime
                  regular do IBS e da CBS para incorporação em seu processo
                  produtivo.
                </td>
              </tr>
              <tr>
                <td rowspan="3">620</td>
                <td rowspan="3">Tributação monofásica</td>
                <td>620001</td>
                <td>Tributação monofásica sobre combustíveis.</td>
                <td>Art. 167</td>
                <td>
                  Art. 167. O IBS e a CBS incidirão uma única vez sobre as
                  operações, ainda que iniciadas no exterior, com os seguintes
                  combustíveis, qualquer que seja a sua finalidade:
                </td>
              </tr>
              <tr>
                <td>620002</td>
                <td>
                  Tributação monofásica com responsabilidade pela retenção sobre
                  combustíveis.
                </td>
                <td>Art. 173</td>
                <td>
                  Art. 173. Fica atribuída à refinaria de petróleo ou suas
                  bases, à CPQ, ao formulador de combustíveis e ao importador,
                  relativamente ao percentual de biocombustível utilizado na
                  mistura, nas operações com gasolina A, a responsabilidade pela
                  retenção e pelo recolhimento do IBS e da CBS incidentes nas
                  importações de EAC ou sobre as saídas do estabelecimento
                  produtor de EAC.
                </td>
              </tr>
              <tr>
                <td>620003</td>
                <td>
                  Tributação monofásica sobre combustíveis cobrada
                  anteriormente.
                </td>
                <td> </td>
                <td> </td>
              </tr>
              <tr>
                <td>800</td>
                <td>Transferência de crédito</td>
                <td>800001</td>
                <td>
                  Transferência de crédito do associado, inclusive as
                  cooperativas singulares, para cooperativa de que participa das
                  operações antecedentes às operações em que fornece bens e
                  serviços e os créditos presumidos.
                </td>
                <td>Art. 271</td>
                <td>
                  Art. 271. O associado sujeito ao regime regular do IBS e da
                  CBS, inclusive as cooperativas singulares, que realizar
                  operações com a redução de alíquota de que trata o<br />{" "}
                  inciso I do caput do art. 270 poderá transferir os créditos
                  das operações antecedentes às operações em que fornece bens e
                  serviços e os créditos presumidos à cooperativa de que
                  participa, não se aplicando o disposto no art. 36 desta Lei
                  Complementar.
                </td>
              </tr>
              <tr>
                <td rowspan="2">810</td>
                <td rowspan="2">Ajustes</td>
                <td>810001</td>
                <td>
                  Crédito presumido sobre o valor apurado nos fornecimentos a
                  partir da Zona Franca de Manaus.
                </td>
                <td>Art. 447</td>
                <td>
                  Art. 447. Ficam concedidos à indústria incentivada na Zona
                  Franca de Manaus créditos presumidos de IBS e de CBS relativos
                  à operação que destine ao território nacional bem material
                  produzido pela própria indústria incentivada na referida área
                  nos termos do projeto econômico aprovado, exceto em relação às
                  operações previstas no art. 444.
                </td>
              </tr>
              <tr>
                <td>810002</td>
                <td>
                  Fusão ou unificação de bens imóveis contíguos com estorno do
                  redutor social.
                </td>
                <td>Art. 255, § 3º</td>
                <td>
                  Art. 255. Na alienação de bem imóvel residencial novo ou de
                  lote residencial realizada por contribuinte sujeito ao regime
                  regular do IBS e da CBS, poderá ser deduzido da base de
                  cálculo do IBS e da CBS redutor social no valor de R$
                  100.000,00 (cem mil reais) por bem imóvel residencial novo e
                  de R$ 30.000,00 (trinta mil reais) por lote residencial, até o
                  limite do valor da base de cálculo, após a dedução do redutor
                  de ajuste. § 3º Na hipótese de fusão ou unificação de bens
                  imóveis contíguos cujas aquisições tenham sido beneficiadas
                  pela dedução da base de cálculo do redutor social de que trata
                  este artigo, o proprietário do imóvel deverá devolver montante
                  correspondente à aplicação da alíquota do IBS e da CBS sobre o
                  valor do redutor social vigente na data da conjugação.
                </td>
              </tr>
              <tr>
                <td>900</td>
                <td>Outros</td>
                <td>900001</td>
                <td>Outras.</td>
                <td> </td>
                <td> </td>
              </tr>
            </tbody>
          </Table>
        </Container>
      </Tabelas>
    </Layout>
  );
}
